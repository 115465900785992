// App SASS variables
@import './variables';

* {
  box-sizing: border-box;
}

body {
  font-family: $font-family-inter;
}

img {
  max-width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
}

h1, h2 {
  color: $heading-color;
  font-family: $font-family-arges;
  font-size: 5rem;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0;
}

h3 {
  color: $heading-color;
  font-family: $font-family-inter;
  font-weight: 1000;
  font-size: 1.8rem;
  margin: 0;
}


h4 {
  font-weight: 600;
  font-size: 1rem;
  text-transform: uppercase;
  margin: $margin-s 0;
}

a {
  color: $nav-color;
  text-decoration: none;
}

ul.double {
  list-style: none;
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  margin-bottom: 1rem;
  padding: 0;
  max-width: 80%;


  li {
    color: $primary-color;
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 140%;
  }
}

p {
  color: $primary-color;
  font-size: 1rem;
}

section {
  margin: 5rem 0;
}

.margin-y-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.margin-y-m {
  margin-top: $margin-m;
  margin-bottom: $margin-m;
}

.margin-y-l {
  margin-top: $margin-l !important;
  margin-bottom: $margin-l !important;
}
.margin-y-xl {
  margin-top: $margin-xl !important;
  margin-bottom: $margin-xl !important;
}

.padding-t-l {
  padding-top: $margin-l;
}

.margin-t-0 {
  margin-top: 0;
}

.margin-t-xs {
  margin-top: $margin-xs !important;
}

.margin-b-s {
  margin-bottom: $margin-s !important;
}

.margin-l-a {
  margin-left: auto;
}

.padding-t-0 {
  padding-top: 0 !important;
}

.app {
  padding: $body-padding;
  padding-top: calc($body-padding + $heading-size);
}

.flex {
  display: flex;
  justify-content: space-between;
}

.center {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.width-20 {
  min-width: 400px;
  max-width: 20vw;
 }

.text-24 {
  font-size: 1.2rem;
}

.app-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .app-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.app-header {
  background-color: #fff;
  min-height: $heading-size-sm;
  display: flex;
  //font-size: calc(10px + 2vmin);
  font-size: 1rem;
  position: fixed;
  padding: $body-padding $body-padding 0;
  width: 100%;
  z-index: 999;
}

.app-heading {
  color: $heading-color;
  font-family: $font-family-arges;
  font-size: 2.2rem;

    a {
      color: $heading-color;
      cursor: pointer;
      text-decoration: none;
    }
}

.app-nav {
  font-family: $font-family-inter;
  margin-left: auto;

    a {
      color: $primary-color;
      cursor: pointer;
      text-transform: uppercase;
      margin-right: 1.5rem;
      position: relative;
      text-decoration: none;

      &:last-child {
        margin-right: 0;
      }

      &:before {
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        height: 1px;
        bottom: -2px;
        left: 0;
        background-color: $primary-color;
        transform: scaleX(0);
        transform-origin: top left;
        transition: transform 0.5s ease;
      }

      &:hover::before {
        transform: scaleX(1);
      }
    }
}

.app-menu-button {
  color: $primary-color;
}

.app-menu li {
  font-family: $font-family-inter !important;
  color: $primary-color !important;
  text-transform: uppercase;

  a {
    font-family: $font-family-inter !important;
    color: $primary-color !important;
  }
}

.app-link {
  color: $heading-color;
  display: inline-block;
  font-size: 1.3rem;
  font-weight: 700;
  text-decoration: underline;
  margin-top: $margin-l;
}

.tile {
  padding-right: 2rem;

  .tile-fade {
    transition: opacity 2s ease;
  }

  &:nth-child(4n-1) {
    padding-right: 0;
    padding-left: 2rem;
  }

  &.align-end {
    align-self: end;
  }
  &.flex {
    flex-direction: column;
  }

  div {
    overflow: hidden;
  }

  div img {
    cursor: pointer;
    width: 100%;
    overflow: hidden;
  }

  &:hover div img {
    transform: scale(1.1);
    transition: 1000ms;
  }

  h4 {
    cursor: pointer;
  }
}

.about {
  p {

  }
}

.app-drawer {
  padding-top: $margin-l;
  padding-bottom: $margin-l;

  .drawer-module {
    margin: $margin-l 0;
  }

  .module-video {

    video {
      width: 100%;
    }
  }

  .close-button {
    cursor: pointer;
    position: absolute;
    right: $body-padding;
    opacity: 0.6;

    svg {
      height: 2rem;
      width: 2rem;
    }
  }

  p {
    text-align: justify;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: $margin-xl;
  }
}

.video-box {
  background-color: $bg-color;
  width: 350px;
}

.MuiDivider-root, .MuiDivider-root:before, .MuiDivider-root:after {
  border-color: $primary-color !important;
}

.trailsText {
  position: relative;
  height: 85px;
  will-change: transform, opacity;
  overflow: hidden;
  color: $heading-color;
  font-family: $font-family-arges;
  font-size: 5rem;
  text-transform: uppercase;
  margin: 0;
}

.trailsText > div {
  padding-right: 0.05em;
  overflow: hidden;
}

.trails-wrapper {
  height: 95px;
  overflow: hidden;
}

@media (max-width: 900px) {
  .tile {
    padding-right: 0;

    &:nth-child(4n-1) {
      padding-right: 0;
      padding-left: 0;
    }
  }

  .hide-md {
    display: none !important;
  }

  .width-20 {
    min-width: 200px;
  }

}

@media (min-width: 901px) {
  .hide-md-up {
    display: none !important;
  }
}

@media (max-width: 600px) {
  .hide-sm {
    display: none !important;
  }
}

@media (min-width: 601px) {
  .app-header {
    align-items: baseline;
    min-height: $heading-size;
  }
  .hide-sm-up {
    display: none !important;
  }
}

