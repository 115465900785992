@font-face {
  font-family: 'LeagueGothic Regular';
  src: local('LeagueGothic Regular'), url(./fonts/LeagueGothic-Regular-VariableFont_wdth.ttf) format('opentype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url(./fonts/Inter-VariableFont_slnt,wght.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

$font-family-arges: "LeagueGothic Regular", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-inter: "Inter", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$heading-color: #7a212f;
$nav-color: #000;
$bg-color: #FFF;

$primary-color: rgba(122, 33, 47, 1);

$body-padding: 3vw;

$margin-xs: 0.25rem;
$margin-s: 0.5rem;
$margin-m: 1rem;
$margin-l: 1.8rem;
$margin-xl: 3rem;

$heading-size: 100px;
$heading-size-sm: 50px;
